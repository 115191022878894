import React from 'react';

import { Layout, Menu, Card } from 'antd';

import data from './tasks.json';

const { Sider } = Layout;

const styles = {
    sider: {
        overflow: 'auto',
        maxHeight: '75vh',
    },
    card: {
        maxHeight: '75vh',
    },
    cardHead: {
        maxHeight: '10%',
        height: '10%',
    },
    cardBody: {
        maxHeight: '90%',
        height: '90%',
        overflow: 'auto',
    },
};

function titleCase(str) {
    str = str.replaceAll('-', ' ');
    return str
        .toLowerCase()
        .split(' ')
        .map(function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');
}

// Sort tasks by their name.
data.sort(function (a, b) {
    return a.taskName.localeCompare(b.taskName, undefined, { numeric: true, sensitivity: 'base' });
});

export class Explore extends React.Component {
    state = {
        selected: data[0],
    };

    onClick = ({ key }) => {
        this.setState({ selected: data[parseInt(key)] });
    };

    render() {
        return (
            <React.Fragment>
                <Layout>
                    <Sider style={styles.sider}>
                        <div className="logo" />
                        <Menu
                            theme="dark"
                            defaultSelectedKeys={['0']}
                            mode="inline"
                            onClick={this.onClick}>
                            {data &&
                                data.length > 0 &&
                                data.map((item, idx) => (
                                    <Menu.Item key={idx} title={titleCase(item.taskName)}>
                                        {titleCase(item.taskName)}
                                    </Menu.Item>
                                ))}
                        </Menu>
                    </Sider>
                    <Layout>
                        <Card
                            title={`ScienceWorld - ${titleCase(this.state.selected.taskName)}`}
                            style={styles.card}
                            headStyle={styles.cardHead}
                            bodyStyle={styles.cardBody}>
                            <code
                                id="textgame"
                                style={{
                                    wordBreak: 'break-word',
                                    textAlign: 'left',
                                    backgroundColor: 'white',
                                    border: 'none',
                                    padding: '0',
                                    whiteSpace: 'normal',
                                }}>
                                <strong>Task Description:</strong>{' '}
                                {this.state.selected.taskDescription}
                                {this.state.selected.path.map((step) => (
                                    <>
                                        <br />
                                        <br />
                                        <strong>&gt; {step.action}</strong>
                                        <br />
                                        <span style={{ whiteSpace: 'pre-wrap' }}>
                                            {step.observation}
                                        </span>
                                        {step.isCompleted === 'true' ? (
                                            <strong style={{ color: 'darkgreen' }}>
                                                {' '}
                                                (Task Completed){' '}
                                            </strong>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                ))}
                            </code>
                        </Card>
                    </Layout>
                </Layout>
            </React.Fragment>
        );
    }
}
