import React from 'react';

export const About = () => {
    return (
        <React.Fragment>
            <h2>
                ScienceWorld: Is your Agent Smarter than a 5<sup>th</sup> Grader?
            </h2>
            <table>
                <tr>
                    <td width="200px">
                        <a href="https://wsxzwps.github.io/" target="_blank" rel="noreferrer">
                            Ruoyao Wang
                        </a>
                    </td>
                    <td width="200px">
                        <a href="http://cognitiveai.org/" target="_blank" rel="noreferrer">
                            Peter Jansen
                        </a>
                    </td>
                    <td width="200px">
                        <a
                            href="https://www.microsoft.com/en-us/research/people/macote/"
                            target="_blank"
                            rel="noreferrer">
                            Marc-Alexandre Côté
                        </a>
                    </td>
                    <td width="200px">
                        <a href="http://prithvirajva.com/" target="_blank" rel="noreferrer">
                            Prithviraj Ammanabrolu
                        </a>
                    </td>
                </tr>
            </table>
            <br />
            <br />

            <div style={{ textAlign: 'justify' }}>
                <img
                    style={{ float: 'right', width: '65%', paddingLeft: '20px' }}
                    src="/trajectory.png"></img>
                <p>
                    No, it is not. Yet. This new benchmark aims at testing the scientific reasoning
                    abilities of contemporary interactive and grounded text agents at the level of a
                    standard North American elementary school curriculum.
                </p>
                <p>
                    Despite the recent transformer-based progress seen in adjacent fields such as
                    question-answering, scientific text processing, and the wider area of natural
                    language processing, we find that current state-of-the-art models are unable to
                    reason about or explain learned science concepts in novel contexts — e.g. models
                    can easily answer{' '}
                    <b>
                        <i>what</i>
                    </b>{' '}
                    the conductivity of a previously seen material is but struggle when asked{' '}
                    <b>
                        <i>how</i>
                    </b>{' '}
                    they would conduct an experiment in a grounded, interactive environment to find
                    the conductivity of an unknown material. This begs the question of whether
                    current models are simply retrieving answers by way of seeing a large number of
                    similar input examples or if they have learned to reason about concepts in a
                    reusable manner.
                </p>
                <p>
                    We hypothesize that agents need to be grounded in interactive environments to
                    achieve such reasoning capabilities. Our experiments provide empirical evidence
                    supporting this hypothesis---finding that an 1.5 million parameter grounded
                    agent trained interactively for 100k steps can outperform a 11 billion parameter
                    model statically trained for scientific question-answering and reasoning via
                    millions of expert demonstrations.
                </p>
            </div>
        </React.Fragment>
    );
};
